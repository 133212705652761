/* Contact sectin start */

.contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    background-color: hsl(0, 0%, 94%)
}

.contact-section .title {
    font-size: 2rem;
    margin-bottom: 2rem;
}

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.contact .prof {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.contact .prof a {
    text-decoration: none;
}

.contact .socials {
    display: flex;
    /* flex-direction: column; */
    gap: 1.5rem;
}

.contact .socials a {
    transition: background-color 300ms;
    font-size: 1.6rem;
}

.contact .socials a:hover {
    color: #555555;
}


.downloadResumeButton {
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0.6rem 1rem;
    background-color: #333333;
    color: var(--colorTextLight);
    border-radius: 0.5rem;
    font-size: 1rem;
}

/* Contact sectin end */

footer {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--colorTextLight);
    background-color: var(--colorTextGray2);
}
