:root {
    --glow-rgb: hsl(312, 86%, 55%);
}

.mouseEffect{
    overflow: hidden;
    position: relative;
    cursor: none;
    user-select: none;
}

.glow-point {
    position: absolute;
    box-shadow: 0rem 0rem 1.2rem 0.6rem var(--glow-rgb);
    pointer-events: none;
    z-index: 12;
}

.star {
    position: absolute;
    z-index: 12;
    color: white;
    font-size: 1rem;
    animation-duration: 1500ms;
    animation-fill-mode: forwards;
    pointer-events: none;
}

@keyframes fall-1 {
    0% {
        transform: translate(0px, 0px) rotateX(45deg) rotateY(30deg) rotateZ(0deg) scale(0.25);
        opacity: 0;
    }

    5% {
        transform: translate(10px, -10px) rotateX(45deg) rotateY(30deg) rotateZ(0deg) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(25px, 200px) rotateX(180deg) rotateY(270deg) rotateZ(90deg) scale(1);
        opacity: 0;
    }
}

@keyframes fall-2 {
    0% {
        transform: translate(0px, 0px) rotateX(-20deg) rotateY(10deg) scale(0.25);
        opacity: 0;
    }

    10% {
        transform: translate(-10px, -5px) rotateX(-20deg) rotateY(10deg) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(-10px, 160px) rotateX(-90deg) rotateY(45deg) scale(0.25);
        opacity: 0;
    }
}

@keyframes fall-3 {
    0% {
        transform: translate(0px, 0px) rotateX(0deg) rotateY(45deg) scale(0.5);
        opacity: 0;
    }

    15% {
        transform: translate(7px, 5px) rotateX(0deg) rotateY(45deg) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(20px, 120px) rotateX(-180deg) rotateY(-90deg) scale(0.5);
        opacity: 0;
    }
}



.wand {
    scale: 1;
    width: 1rem;
    aspect-ratio: 1 / 15;
    background: linear-gradient(to right,
            rgb(26 24 28) 10%,
            rgb(42 40 44) 45% 55%,
            rgb(26 24 28) 90%);
    position: absolute;
    /* translate: 0 0; */
    rotate: -3deg;
    z-index: 10;
    border-radius: 3vmin;
    box-shadow: 0vmin 1vmin 4vmin rgb(0 0 0 / 80%);
    overflow: hidden;
    transition: opacity 400ms, scale 400ms;
    transform-origin: top;
}

.mouseEffect:not(:hover) .wand{
    opacity: 0;
    scale: 0;
}

.wand>.cap {
    height: 20%;
    width: 100%;
    background: linear-gradient(to right,
            rgb(212 221 236) 10%,
            rgb(255 255 255) 45% 55%,
            rgb(212 221 236) 90%);
}

body[mobileOrTablet="true"] .wand{
    display: none;
}