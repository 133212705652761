.project {
    display: flex;
    /* margin-bottom: 2rem; */
    /* border: 1px solid black; */
    min-height: 15rem;
    position: relative;
    margin-bottom: 1rem;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: var(--color, hsla(0, 0%, 90%, 0.902));
}

.project-background {
    /* display: none; */
    inset: 0;
    position: absolute;
    z-index: -1;

    filter: blur(10px);
    background: white;
    opacity: 0.3;
}

.project-head {
    padding: 2rem;
    flex: 1;
    border-right: 1px solid black;
}

.project-name {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.project-body {
    padding: 2rem;
    flex: 6;
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.project-desc {
    grid-column: 1/2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 2rem;
}

.project-desc .description {
    text-align: left;
    font-size: 1.1rem;
}

.project-link {
    color: inherit;
    display: flex;
    align-items: center;
    transition: color 300ms;
}

.project-link i {
    margin-left: 0.5rem;
}

.project-image {
    grid-column: 2/3;
}

.project-image img {
    max-width: 100%;
    /* height: 100%; */
    /* object-fit: cover; */
}

@media screen and (max-width: 1000px) {
    .project {
        flex-direction: column;
    }

    .project-head {
        align-self: center;
        text-align: center;
        padding: 2rem;
        flex: 1;
        border: none;
        /* border-bottom: 1px solid black; */
    }

    .project-body {
        grid-template-columns: 1fr;
        padding-top: 0;
    }

    .project-desc {
        grid-column: 1/2;
        grid-row: 2/3;
        padding: 0;

    }

    .project-image {
        display: flex;
        grid-row: 1/2;
        grid-column: 1/2;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1.5rem;
    }

    .project-image img {
        width: 70%;
    }

    .project-link {
        margin-top: 1rem;
        justify-content: flex-end;
    }
}

@media screen and (max-width: 600px) {
    .project-head {
        padding-bottom: 0.5rem;
    }

    .project-body {
        padding: 1rem;
    }

    .project-image img {
        width: 100%;
    }
}