.header-logo {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    left: 2rem;
    z-index: 99;
    /* filter: drop-shadow(0px 0px 4px black); */
}

.jellyBounce:hover {
    animation: jello-horizontal 0.9s both;
}


@keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.header-logo svg {
    width: 4rem;
    height: fit-content;
}

.nav-menu-toggler-wrapper {
    cursor: pointer;
    position: fixed;
    top: 1rem;
    right: 2rem;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -10px 10px 0px inset,
        rgba(0, 0, 0, 0.15) 0px -15px 15px 0px inset,
        rgba(0, 0, 0, 0.1) 0px -40px 20px 0px inset,
        rgba(0, 0, 0, 0.06) 0px 2px 1px,
        rgba(0, 0, 0, 0.09) 0px 4px 2px,
        rgba(0, 0, 0, 0.09) 0px 8px 4px,
        rgba(0, 0, 0, 0.09) 0px 16px 8px,
        rgba(0, 0, 0, 0.09) 0px 32px 16px,
        0px -1px 15px -8px rgba(0, 0, 0, 0.09);
    background-color: rgb(232, 232, 232, 1);
    width: 4rem;
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    z-index: 9999;
}

.nav-menu-toggler {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1px;
}

.nav-menu-toggler div {
    /* transform: translate(1.244px, -0.1035px); */
    width: 50%;
    height: 1px;
    background-color: rgb(43, 43, 43);
    margin-block: 2px;
    transition: 0.5s transform;
}

.nav-menu-toggler[area-expanded="true"] .line1 {
    transform: translateY(3.25px) rotate(45deg);
}

.nav-menu-toggler[area-expanded="true"] .line2 {
    transform: rotatez(180deg);
    opacity: 0;
}

.nav-menu-toggler[area-expanded="true"] .line3 {
    transform: translateY(-3.25px) rotate(-45deg);
}

.header-content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@media screen and (max-width: 570px) {
    .header-logo svg {
        width: 3rem;
    }

    .nav-menu-toggler-wrapper {
        width: 3rem;
    }
}


/* Menu css start*/

.nav-menu {
    position: fixed;
    inset: 0;
    /* bottom: 100px; */
    background-color: #2a2a2a;
    z-index: 999;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-around;
    transition: border-radius 1000ms, transform 700ms;
    border-radius: 0;
    color: var(--colorTextLight);
}

.nav-menu[data-visible="false"] {
    /* border-radius: 0 0 50% 50%; */
    transform: translateY(-100%);
    /* transition: border-radius 5000ms ,transform 700ms; */
}

.nav-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nav-link {
    font-size: 4rem;
    margin-block: 0.5rem;
}

.nav-link a{
    color: inherit;
    text-decoration: none;
}

.nav-link a::before{
    content: 'You are here';
    display: block;
    font-size: 0.9rem;
    position: absolute;
    rotate: -40deg;
    background-color: var(--colorTextLight);
    color: var(--colorTextDark);
    padding: 0.4rem 0.8rem;
    border-radius: 0.3rem;
    left: -3.5rem;
    top: 10%;
    opacity: 0;
    transition: opacity 1000ms;
    font-weight: 500;
}

.nav-link .Active::before{
    opacity: 1;
    /* width: 20px;
    aspect-ratio: 1;
    background-color: var(--colorTextLight);
    position: absolute;
    left: -3rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%; */
}

.nav-link::after,
.nav-socials-link::after {
    content: '';
    display: block;
    height: 1px;
    width: 0%;
    background-color: var(--colorTextLight);
    transition: width 300ms;
}

.nav-link:hover::after,
.nav-socials-link:hover::after {
    width: 100%;
}

.nav-link,
.nav-socials-link {
    cursor: pointer;
}

.nav-socials {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nav-socials-link {
    font-size: 1.2rem;
    margin-block: 0.3rem;
    color: inherit;
    text-decoration: none;
}

@media screen and (max-width: 900px) {
    .nav-link {
        font-size: 3rem;
        margin-block: 0.5rem;
    }

    .nav-socials-link {
        font-size: 1rem;
        margin-block: 0.3rem;
    }
}

@media screen and (max-width: 768px) {
    .nav-menu[data-visible="false"] {
        border-radius: 0 0 0 0;
        transform: translateY(-100%);
        /* transition: border-radius 5000ms ,transform 700ms; */
    }
}

@media screen and (max-width: 600px) {
    .nav-menu {
        flex-direction: column;
    }

    .nav-link {
        font-size: 2.5rem;
    }

    .nav-link a::before{
        font-size: 0.7rem;
    }

    .nav-socials {
        align-self: center;
        flex-direction: row;
        align-items: center;
    }

    .nav-socials-link {
        margin-inline: 0.5rem;
    }
}

/* Menu css end*/

.magneticMenuInside:not(:hover), .magneticMenu:not(:hover){
    transition: transform 300ms;
}