.not-found-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    position: fixed;
    inset: 0;
    padding: 1rem;
}

.not-found{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    text-align: center;
    gap: 3rem;
    margin-top: 25vh;
}