.projects-main{
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5rem;
    padding-inline: 2rem;
    padding-bottom: 2rem;
    background-color: hsl(200, 14%, 65%);
    color: var(--colorTextDark);
}

.projects-main .title{
    margin-bottom: 2rem;
}