:root {
    --sparkleYello: #fcf6bd;
    --sparklePurple: #e4c1f9;
    --sparklePink: #ff99c8;
}

@keyframes background-pan {
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
}

@keyframes scale {

    from,
    to {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
}

.magic {
    display: inline-block;
    position: relative;
}

.magic>.magic-star {
    --size: clamp(15px, 1.5vw, 25px);

    animation: scale 700ms ease forwards;
    display: block;
    height: var(--size);
    left: var(--star-left);
    position: absolute;
    top: var(--star-top);
    width: var(--size);
}

.magic>.magic-star>svg {
    animation: rotate 1000ms linear infinite;
    display: block;
    opacity: 0.7;
}

.magic>.magic-star>svg>path {
    fill: var(--sparkleYello);
}

.magic>.magic-text {
    margin-inline: 0.3rem;
    animation: background-pan 3s linear infinite;
    background: linear-gradient(to right,
            var(--sparklePink),
            var(--sparklePurple),
            var(--sparkleYello),
            var(--sparklePink));
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
}