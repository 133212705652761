.about-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: hsl(0, 0%, 90%);
    color: var(--colorTextDark);
}

.about-content-wrapper {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    padding-top: 5rem;
    padding-inline: 2rem;
    padding-bottom: 2rem;
    max-width: 1200px;
}

.about-main section {
    /* margin-bottom: 1rem; */
    padding-block: 3rem;
}

.about-main .section-aboutMe,
.about-main .section-exp {
    border-bottom: 1px solid #33333388;
}

.about-main section>.title {
    margin-bottom: 2rem;
}

.profile {
    display: flex;
    gap: 3rem;
}

.profile-header {
    width: 100%;
}

.profile-header-name {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.profile .profile-text {
    /* max-width: 75ch; */
    text-align: justify;
}

.experience-wrapper .experience {
    margin-bottom: 1.5rem;
}

.experience-wrapper .experience .title {
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.about-main .section-skills {
    padding-bottom: 0;
}

.skill-div {
    margin-bottom: 1.5rem;
}

.skill-div .skill-title {
    font-weight: 600;
    margin-bottom: 0.5rem;
}

@media screen and (max-width: 800px) {
    .profile {
        flex-direction: column;
        gap: 1.5rem;
    }
}