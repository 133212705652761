.intro-wrapper {
    /* padding-block: 10rem; */
    min-height: 100vh;
    background: linear-gradient(145deg, #772ec3, #3a1299);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.intro-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}

.intro-text-im {
    /* transform: translate(-10px, 0px); */
    font-size: 1.7rem;
    color: aliceblue;
}

.intro-text-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    margin-bottom: 1rem;
}

.intro-text-name span {
    cursor: default;
    font-size: 6rem;
    font-weight: 400;
    line-height: 90%;
    letter-spacing: -4px;
    color: aliceblue;
    /* text-transform: uppercase; */
    /* font-family: 'Sacramento', cursive; */
}

.intro-text-desc {
    text-align: center;
}

.intro-text-desc p {
    color: aliceblue;
}

.floating-title {
    position: absolute;
    transform-style: preserve-3d;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--colorTextLight);
    font-size: 1.2rem;
    text-shadow: 4px 4px 10px black;
    transition: scale 300ms, text-shadow 300ms;
    animation: float 6s ease-in-out infinite;
}

.floating-title:hover {
    scale: 1.2;
    text-shadow: 4px 4px 10px black, 0px 0px 5px var(--colorTextLight);
}

@keyframes float {
    0% {
        transform: translate(10px, 0px) rotate(0deg);
    }

    50% {
        transform: translate(0, -20px) rotate(0deg);
    }

    100% {
        transform: translate(10px, 0px) rotate(0deg);
    }
}

.floating-title:nth-of-type(1) {
    left: 15%;
    top: 50%;
}

.floating-title:nth-of-type(2) {
    right: 15%;
    top: 30%;
}

.floating-title:nth-of-type(3) {
    right: 17%;
    top: 70%;
}

.floating-title:nth-of-type(1) span:nth-child(1),
.floating-title:nth-of-type(2) span:nth-child(2),
.floating-title:nth-of-type(3) span:nth-child(1) {
    display: inline-block;
}

@media screen and (max-width: 920px) {
    .floating-title:nth-of-type(1) {
        left: 5%;
        top: 30%;
    }

    .floating-title:nth-of-type(2) {
        right: 5%;
        top: 20%;
    }

    .floating-title:nth-of-type(3) {
        right: 7%;
        top: 85%;
    }
}

@media screen and (max-width: 570px) {

    .floating-title:nth-of-type(1),
    .floating-title:nth-of-type(2),
    .floating-title:nth-of-type(3) {
        display: none;
    }
}

.custom-shape-divider-bottom-1700892787 {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    z-index: 11;
}

.custom-shape-divider-bottom-1700892787 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 85px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1700892787 .shape-fill {
    fill: url("#myGradient");
}

/* main-intro end */

/* what i do start */

.what-i-do-section {
    padding-top: 5rem;
    padding-bottom: 6rem;
    padding-inline: 2rem;
    background: linear-gradient(90deg, var(--gradientC1) 0%, var(--gradientC2) 100%);
    position: relative;
}

.what-i-do-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--colorTextLight);
}

.what-i-do-wrapper .title {
    font-size: 2rem;
    margin-bottom: 2rem;
}

.what-i-do-wrapper .text {
    font-size: 1.1rem;
    max-width: 70ch;
    text-align: center;
    margin-bottom: 1rem;
}

.mail-link {
    margin-inline: 0.5rem;
    color: inherit;
    text-decoration: underline;
    transition: color 300ms;
}

.mail-link:hover {
    color: var(--colorTextGray2);
}

.custom-shape-divider-bottom-1700908248 {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1700908248 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 75px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1700908248 .shape-fill {
    fill: #d5d1f4;
}

@media screen and (max-width: 768px) {
    .shape-divider {
        display: none;
    }

    .what-i-do-section {
        padding-block: 4rem;
    }
}

/* what i do end */


/* projects section start */

.custom-shape-divider-top-1701353953 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1701353953 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 60px;
}

.custom-shape-divider-top-1701353953 .shape-fill {
    fill: url("#myGradient");
}

.projects-section {
    padding-top: 4rem;
    padding-bottom: 2rem;
    padding-inline: 3rem;
    color: var(--colorTextDark);
    min-height: 100vh;
    background-color: #d5d1f4;
    overflow-x: hidden;
    position: relative;

}

.projects-section-content-wrapper {}

.projects-section-content-wrapper .title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 3rem;
}

.project-link-wrapper {
    display: flex;
    justify-content: center;
}

.project-link-wrapper .project-link {
    font-size: 1.3rem;
}

@media screen and (max-width: 768px) {
    .projects-section {
        padding: 2rem 2rem;
    }

    .projects-section-content-wrapper .title {
        margin-bottom: 2rem;
    }

    .project-link-wrapper .project-link {
        font-size: 1.2rem;
    }
}


/* projects section end */

/* Main end */