@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --colorTextLight: #fefefe;
  --colorTextDark: #020202;
  --colorTextGray: #888888;
  --colorTextGray1: #e4e4e4;
  --colorTextGray2: #333333;


  --colorScrollBar: hsl(0, 0%, 33%);
  --colorScrollBarBackground: hsl(0, 0%, 83%);


  --gradientC1: hsla(270, 91%, 60%, 1);
  --gradientC2: hsla(290, 56%, 56%, 1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

a{
  color: inherit;
}

::selection{
  background-color: #333333;
  color: var(--colorTextLight);
}

body {
  /* padding-inline: min(5vw, 2rem); */
  overflow-x: hidden;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: var(--colorScrollBarBackground);
}

::-webkit-scrollbar {
  width: 8px;
  background-color: var(--colorScrollBarBackground);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--colorScrollBar);
}

.magneticEffect {
  transition: transform 100ms;
}

.magneticEffect:not(:hover) {
  transition: transform 300ms;
}