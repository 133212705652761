.followMouse {
    position: relative;

    transform-style: preserve-3d;
    transform: perspective(5000px) rotateY(var(--rotateX)) rotateX(var(--rotateY));
    /* transition: transform 300ms; */
}

/* shadow */

.followMouse .depth-1,
.followMouse .depth-2,
.followMouse .depth-3 {
    position: relative;
}

.followMouse.notActive {
    transform: perspective(500px) rotateY(0) rotateX(0);
    transition: transform 300ms;
}

.followMouse .depth-1 {
    transform: translateZ(-50px) scale(1.2) translateY(-10px);
}

.followMouse .depth-2 {
    transform: translateZ(0px);
}

.followMouse .depth-3 {
    transform: translateZ(50px);
}